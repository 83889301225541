.modal {
    padding: 32px;
    @media (max-width: 700px) {
        width: 100vw;
        height: 100vh;
        max-width: none;
        border-radius: 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    @media (max-width: 700px) {
        height: 95vh;
        max-width: none;
        justify-content: space-between;
    }
}

.term {
    margin-top: 42px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.checkbox {
    margin-right: 8px;
    cursor: pointer;
}

.link {
    text-decoration: underline;
}

.description {
    line-height: 24px;
}

.button {
    @media (max-width: 700px) {
        margin-bottom: 24px;
    }
}

.test {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
}

.test2 {
    height: 100% !important;
    max-height: 100vh !important;
}
